import * as types from "./types";
import * as pageTypes from "../page/types";

const transformedSession = (session: any) => {
  if (
    !Object.keys(session).length || !session.order_type ||
    (session.order_type == "delivery" && !session.area_id) ||
    (session.order_type == "pickup" && !session.outlet_id)
  ) {
    session.showAreaSelect = true;
    session.mandatory = true;
  } else {
    session.showAreaSelect = false;
    session.mandatory = false;
  }
  return session;
}

const sessionReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return transformedSession(action.payload.data.session);
    case types.SET_SESSION:
      return {
        ...state,
        ...action.payload,
      };
    case types.CHANGE_DELIVERY_AREA_SUCCESS:
      return action.payload.data;

    default: return state;
  }
};

const reducer = sessionReducer;

export default reducer;

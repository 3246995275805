// Packages
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import DiscountWidgetMobile from "../../mobileComponents/discountWidgetMobile";
import LoyaltyWidgetMobile from "../../mobileComponents/loyaltyWidgetMobile";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faMinus,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { cartOperations } from "../../state/features/cart";
import { pageOperations } from "../../state/features/page";
import { loaderOperations } from "../../state/features/loader";
import { errorOperations } from "../../state/features/error";
import { areasOperations } from "../../state/features/areas";
// Helpers, Utils etc.
import {
  formatDecimal,
  calculateAmountsAndBreakdowns,
} from "../../helpers/itemCalculations";
import {
  updateItemWithCartIdInCart,
  removeItemWithCartId,
} from "../../helpers/cartFunctions";
import { validateCartItems, outOfStockItemsList } from "../../helpers/cartFunctions";
import {
  translatedName,
} from "../../helpers/translations";
import OutOfStockWarning from "../../mobileComponents/outOfStockWarningMobile";
import PaymentInProgressBanner from "../../mobileComponents/paymentInProgressBanner";

interface IOrderDetailsScreenProps {
  cart: any;
  setCart: any;
  menu: any;
  orderType: any;
  currency: any;
  hasLoyalty: boolean;
  redeemedPoints: any;
  discount: any;
  user: any;
  loyalty: any;
  deliveryAreas: any;
  areaId: any;
  outlet: any;
  storeOpen: boolean;
  location: any;
  selectedArea: any;
  showAreaSelect: boolean;
  fetchOrderDetailsConfigurations: any;
  showLoader: any;
  updateError: any;
  setArea: any;
  outOfStock: any[];
  recentOrder: any;
}

function OrderDetailsScreen(props: IOrderDetailsScreenProps) {
  const [cartItems, setCartItems] = useState([] as any);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [redeemedLoyaltyPoints, setRedeemedLoyaltyPoints] = useState(0);
  const [surchargeBreakDown, setSurchargeBreakDown] = useState([] as any);
  const [amountToReachMinOrderValue, setAmountToReachMinOrderValue] = useState(0);
  const [deliveryAreas, setDeliveryAreas] = useState([] as any);
  const [paymentDetails, setPaymentDetails] = useState({} as any);

  const intl = useIntl();

  useEffect(() => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      props.cart.items,
      props.menu,
      props.orderType,
      props.discount,
      props.redeemedPoints,
      props.selectedArea,
      props.outlet.delivery_charge_id,
    );
    setCartItems(props.cart.items);
    setSubTotal(amountBreakdowns.subTotal);
    setTotal(amountBreakdowns.total);
    setTaxTotal(amountBreakdowns.taxTotal);
    setDiscountTotal(amountBreakdowns.discountTotal);
    setRedeemedLoyaltyPoints(amountBreakdowns.loyaltyTotal);
    setSurchargeBreakDown(amountBreakdowns.surchargeBreakDown);
    window.scrollTo(0, 0);
    if (props.deliveryAreas && props.orderType === "delivery") {
      findDeliveryArea();
      checkMinOrderValue(amountBreakdowns.subTotal);
    }
  }, [props.cart, props.areaId]);

  useEffect(() => {
    props.fetchOrderDetailsConfigurations().then((response: any) => {
      if (!response.error) {
        updateItemsInCart(
          validateCartItems(
            response.payload.data.menu.categories,
            JSON.parse(localStorage.cartItems || "[]"),
            itemsNotInMenuError
          )
        );
      }
    });
    verifyPaymentStatus();
  }, []);

  useEffect(() => {
    if (props.showAreaSelect) {
      props.updateError({
        titleCode: "error.oops",
        messageCode: "error.unable_to_process_order",
        redirectionUrl: "/m",
      });
    }
  }, [props.showAreaSelect]);

  useEffect(() => {
    updateItemsInCart(props.cart.items);
  }, [props.cart.items]);

  const findDeliveryArea = () => {
    let area = props.deliveryAreas.find((area: any) => {
      return area.id == props.areaId;
    });
    props.setArea(area);
  }

  const outOfStockInCart = useMemo(() => {
   return outOfStockItemsList(cartItems, props.outOfStock);
  },[cartItems]);

  const checkMinOrderValue = (totalAmount: any) => {
    if (Number(totalAmount) < Number(props.selectedArea.min_order_value)) {
      setAmountToReachMinOrderValue(
        Number(props.selectedArea.min_order_value) - Number(totalAmount)
      );
    } else {
      setAmountToReachMinOrderValue(0)
    }
  };

  const updateItemsInCart = (cartItems: any) => {
    let cart = calculateAmountsAndBreakdowns(
      cartItems,
      props.menu,
      props.orderType,
      props.discount,
      props.redeemedPoints,
      props.selectedArea,
      props.outlet.delivery_charge_id,
    ) as any;
    cart.items = cartItems;

    props.setCart(cart);
  };

  const itemsNotInMenuError = () => {
    props.updateError({
      title: intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
      message: intl.formatMessage({ id: "error.items_in_cart_not_in_menu", defaultMessage: "Items in cart are not part of current available menu." }),
    });
  }

  const verifyPaymentStatus = () => {
    let urlParams = new URLSearchParams(props.location.search);
    let paymentStatus = urlParams.get("payment_status");
    let message = urlParams.get("message");

    if (paymentStatus == "failed") {
      setPaymentDetails({
        status: paymentStatus,
        message: message,
      });
    }
  }

  const incrementCount = (item: any) => {
    if(props.menu.out_of_stock_items.includes(item.id)){
      return;
    }
    item.count = item.count + 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const decrementCount = (item: any) => {
    item.count = item.count - 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const removeItem = (item: any) => {
    removeItemWithCartId(item, props.cart.items, props.setCart);
  };

  let modifierBreakdown = (menuItem: any) =>
    menuItem.groups.map((group: any) => {
      let groups = group.items.map((item: any) => {
        let selected_item;
        if (item.selected === true) {
          selected_item = item;
        }
        return (
          selected_item && (
            <div className="modifier" key={selected_item.id}>
                {translatedName(selected_item, intl.locale)}
            </div>
          )
        );
      });
      return groups;
    });

  const proceedToCheckout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!cartItems || cartItems.length <= 0) {
      e.preventDefault();
      return;
    }
    if (!props.user.id) {
      localStorage.setItem("postRegistrationPage", "/m/checkout");
    }
    props.showLoader();
  };
  return (
    <div className="order-details-screen mt-0">
      {paymentDetails.status == "failed" ? (
        <div className='payment-failure'>
          <p className="error-header">
            <FormattedMessage
              id="payment.payment_failed"
              defaultMessage="Payment Failed"
            />
          </p>
          <p className="error-message">
            <FormattedMessage
              id="payment.unable_to_process_payment"
              defaultMessage="Sorry, we were unable to process your payment. Your card was not charged. Please try again or use a different payment method."
            />
          </p>
          <p className="error">{paymentDetails.message}</p>
        </div>
      ) : null}
      
      {outOfStockInCart.length ? (
        <div className="out-of-stock-warning">
          <OutOfStockWarning outOfStockItems={outOfStockInCart} />
        </div>
      ) : null}
      
      <div className="order-details">
        {cartItems.map((cartItem: any, index: any) => {
          return (
            <div key={index}>
              <div
                className={
                  cartItem.groups.length
                    ? "order-item with-group"
                    : "order-item"
                }
              >
                <div
                  className="delete-icon"
                  onClick={() => removeItem(cartItem)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div
                  className={`${
                    props.menu.out_of_stock_items.includes(cartItem.id)
                      ? "out-of-stock-item-details"
                      : ""
                  } item-details`}
                >
                  <p className="item-name">
                      {translatedName(cartItem, intl.locale)}
                  </p>
                  <p className="item-price">
                    {props.currency}{" "}
                    {formatDecimal(cartItem.sub_total / cartItem.count)}
                  </p>
                </div>
                <div className="count-controller">
                  <p
                    className="icon"
                    onClick={() => {
                      if(props.menu.out_of_stock_items.includes(cartItem.id)){
                        return;
                      }
                      cartItem.count > 1
                        ? decrementCount(cartItem)
                        : removeItem(cartItem);
                    }}
                  >
                    <FontAwesomeIcon size="xs" icon={faMinus} />
                  </p>
                  <p className="count">{cartItem.count}</p>
                  <p className="icon" onClick={() => incrementCount(cartItem)}>
                    <FontAwesomeIcon size="xs" icon={faPlus} />
                  </p>
                </div>
              </div>
              {cartItem.groups.length ? (
                <div className="selected-modifiers">
                  {modifierBreakdown(cartItem)}
                </div>
              ) : (
                false
              )}
            </div>
          );
        })}
      </div>
      <div className="other-details footer">
        <DiscountWidgetMobile />
        {props.hasLoyalty ? <LoyaltyWidgetMobile /> : null}
        <div className="charges-breakdown">
          <div className="charge">
            <p>
              <FormattedMessage
                id="global.subtotal"
                defaultMessage="Subtotal"
              />
            </p>
            <p>
              {props.currency} {formatDecimal(subTotal)}
            </p>
          </div>
          {surchargeBreakDown.map((surcharge: any, index: any) => {
            return (
              <div className="charge" key={index}>
                <p className="label">{surcharge.name}</p>
                <p className="value">
                  {props.currency} {formatDecimal(surcharge.amount)}
                </p>
              </div>
            );
          })}
          {props.discount ? (
            <div className="charge">
              <p className="label">{props.discount.discount_name}</p>
              <p className="value">
                - {props.currency}{" "}
                {formatDecimal(props.discount.discountedAmount)}
              </p>
            </div>
          ) : null}
          {props.redeemedPoints ? (
            <div className="charge">
              <p className="label">
                <FormattedMessage
                  id="global.loyalty"
                  defaultMessage="Loyalty"
                />
              </p>
              <p className="value">
                - {props.currency} {formatDecimal(props.redeemedPoints)}
              </p>
            </div>
          ) : null}
          {!props.menu.is_tax_inclusive && taxTotal > 0 ? (
            <div className="charge">
              <p>
                <FormattedMessage
                  id="global.tax"
                  defaultMessage="Tax"
                />
              </p>
              <p>
                {props.currency} {formatDecimal(taxTotal)}
              </p>
            </div>
          ) : null}
          <div className="charge strong">
            <p>
              <FormattedMessage
                id="global.total"
                defaultMessage="Total"
              />
            </p>
            <p>
              {props.currency} {formatDecimal(total)}
            </p>
          </div>
          {amountToReachMinOrderValue ? (
            <div className="minimumAmountNotice">
              <FormattedMessage
                id="checkout.add_more_to_checkout"
                defaultMessage="Add {currency} {amount} more to Checkout"
                values={{
                  currency: props.currency,
                  amount: formatDecimal(amountToReachMinOrderValue),
                }}
              />
            </div>
          ) : null}

          {props.recentOrder ? (
            <div className="in-progress-banner-wrapper">
              <PaymentInProgressBanner />
            </div>
          ) : null}

          <div className="d-flex">
            <Link
              onClick={() => props.showLoader()}
              to="/m"
              className={"button btn btn-outline-primary w-100 mr-3"}
            >
              <FormattedMessage
                id="cart.add_more_items"
                defaultMessage="Add more items"
              />
            </Link>
            <Link
              onClick={proceedToCheckout}
              to={props.user.id ? "/m/checkout" : "/m/login"}
              className={
                cartItems?.length >= 1 &&
                !amountToReachMinOrderValue &&
                props.storeOpen &&
                outOfStockInCart?.length === 0 &&
                !props.recentOrder
                  ? "button btn btn-primary w-100"
                  : "button btn btn-secondary disabled w-100"
              }
            >
              <FormattedMessage
                id="global.checkout"
                defaultMessage="Checkout"
              />
              <span className="ml-3">
                <FontAwesomeIcon icon={faChevronCircleRight} />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;
  let menu = state.menu;
  let cart = state.cart;
  let orderType = state.session.order_type;
  let loyaltyConfig = state.loyaltyConfig || {};
  let hasLoyalty = loyaltyConfig[orderType]?.enabled;
  let redeemedPoints = state.loyalty?.redeemedPoints;
  let discount = state.discount;
  let user = state.user;
  let deliveryAreas = state.outlet?.delivery_areas;
  let areaId = state.session.area_id;
  let outlet = state.outlet;
  let loyalty = state.loyalty;
  let storeOpen = state.storeTimings.open;
  let selectedArea = state.areas.selectedArea;
  let showAreaSelect = state.session.showAreaSelect;
  let outOfStock = state.menu.out_of_stock_items;
  let recentOrder = state.recentOrder;

  return {
    currency,
    cart,
    menu,
    orderType,
    hasLoyalty,
    redeemedPoints,
    discount,
    user,
    deliveryAreas,
    areaId,
    outlet,
    loyalty,
    storeOpen,
    selectedArea,
    showAreaSelect,
    outOfStock,
    recentOrder
  };
};

const mapDispatchToProps = {
  setCart: cartOperations.setCart,
  fetchOrderDetailsConfigurations:
    pageOperations.fetchOrderDetailsConfigurations,
  showLoader: loaderOperations.showLoader,
  updateError: errorOperations.updateError,
  setArea: areasOperations.setArea,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsScreen);

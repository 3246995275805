export const VERIFY_PAYMENT = "paymentTypes/VERIFY_PAYMENT";
export const VERIFY_PAYMENT_SUCCESS = "paymentTypes/VERIFY_PAYMENT_SUCCESS";
export const VERIFY_PAYMENT_FAIL = "paymentTypes/VERIFY_PAYMENT_FAIL";
export const BUILD_CC_AVENUE_PAYMENT_DATA = "paymentTypes/BUILD_CC_AVENUE_PAYMENT_DATA";
export const BUILD_CC_AVENUE_PAYMENT_DATA_SUCCESS = "paymentTypes/BUILD_CC_AVENUE_PAYMENT_DATA_SUCCESS";
export const BUILD_CC_AVENUE_PAYMENT_DATA_FAIL = "paymentTypes/BUILD_CC_AVENUE_PAYMENT_DATA_FAIL";
export const FETCH_MY_FATOORAH_PAYMENT_MODES = "paymentTypes/FETCH_MY_FATOORAH_PAYMENT_MODES";
export const FETCH_MY_FATOORAH_PAYMENT_MODES_SUCCESS = "paymentTypes/FETCH_MY_FATOORAH_PAYMENT_MODES_SUCCESS";
export const FETCH_MY_FATOORAH_PAYMENT_MODES_FAIL = "paymentTypes/FETCH_MY_FATOORAH_PAYMENT_MODES_FAIL";
export const BUILD_QPAY_PAYMENT_DATA = "paymentTypes/BUILD_QPAY_PAYMENT_DATA";
export const BUILD_QPAY_PAYMENT_DATA_SUCCESS = "paymentTypes/BUILD_QPAY_PAYMENT_DATA_SUCCESS";
export const BUILD_QPAY_PAYMENT_DATA_FAIL = "paymentTypes/BUILD_QPAY_PAYMENT_DATA_FAIL";
export const BUILD_I_PAY88_PAYMENT_DATA = "paymentTypes/BUILD_I_PAY88_PAYMENT_DATA";
export const BUILD_FISERV_PAYMENT_DATA = "paymentTypes/BUILD_FISERV_PAYMENT_DATA";
export const BUILD_FISERV_PAYMENT_DATA_SUCCESS = "paymentTypes/BUILD_FISERV_PAYMENT_DATA_SUCCESS";
export const BUILD_FISERV_PAYMENT_DATA_FAIL = "paymentTypes/BUILD_FISERV_PAYMENT_DATA_FAIL";
export const OMISE_PLACE_ORDER = "paymentTypes/OMISE_PLACE_ORDER";
export const BUILD_ADYEN_PAYMENT_METHODS = "paymentTypes/BUILD_ADYEN_PAYMENT_METHODS";
export const BUILD_ADYEN_PAYMENT_METHODS_FAIL = "paymentTypes/BUILD_ADYEN_PAYMENT_METHODS_FAIL";
export const CREATE_ADYEN_PAYMENT = "paymentTypes/CREATE_ADYEN_PAYMENT";
export const BUILD_MASTERCARD_SESSION = "paymentTypes/BUILD_MASTERCARD_SESSION";
export const BUILD_MASTERCARD_SESSION_SUCCESS = "paymentTypes/BUILD_MASTERCARD_SESSION_SUCCESS";
export const BUILD_MASTERCARD_SESSION_FAIL = "paymentTypes/BUILD_MASTERCARD_SESSION_FAIL";
export const INITIATE_STRIPE_CONNECT_TRANSACTION = "paymentTypes/INITIATE_STRIPE_CONNECT_TRANSACTION";
export const INITIATE_STRIPE_CONNECT_TRANSACTION_SUCCESS = "paymentTypes/INITIATE_STRIPE_CONNECT_TRANSACTION_SUCCESS";
export const INITIATE_STRIPE_CONNECT_TRANSACTION_FAIL = "paymentTypes/INITIATE_STRIPE_CONNECT_TRANSACTION_FAIL";
export const FETCH_STRIPE_CONNECT_PAYMENT_INTENT = "paymentTypes/FETCH_STRIPE_CONNECT_PAYMENT_INTENT";
export const FETCH_STRIPE_CONNECT_PAYMENT_INTENT_SUCCESS = "paymentTypes/FETCH_STRIPE_CONNECT_PAYMENT_INTENT_SUCCESS";
export const FETCH_STRIPE_CONNECT_PAYMENT_INTENT_FAIL = "paymentTypes/FETCH_STRIPE_CONNECT_PAYMENT_INTENT_FAIL";

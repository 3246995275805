// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import ModalCloseIcon from "../modalCloseIcon";
// Redux Operations
import { errorOperations } from "../../state/features/error";

interface IErrorPopupProps {
  error: any;
  contactNumber: string;
  resetError: any;
}

function ErrorPopup(props: IErrorPopupProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.error ? true : false);
  }, [props.error])

  const handleClose = () => {
    if (props.error.redirectionUrl) {
      location.href = props.error.redirectionUrl;
    } else {
      setShow(false);
      setTimeout(() => {
        props.resetError();
      }, 500);
    }
  };

  const errorTitle = (titleCode: string) => {
    switch (titleCode) {
      // Add cases for other error title codes
      default: {
        return <FormattedMessage
          id="error.oops"
          defaultMessage="Oops!"
        />;
      }
    }
  }

  const errorSubTitle = (subTitleCode: string) => {
    switch (subTitleCode) {
      // Add cases for other error subtitle codes
      case "error.out_of_stock":
        return <FormattedMessage
          id="error.out_of_stock_title"
          defaultMessage="Item/s unavailable"
        />;
      default: {
        return <FormattedMessage
          id="error.oops"
          defaultMessage="Oops!"
        />;
      }
    }
  }

  const errorMessage = (errorCode: string) => {
    switch (errorCode) {
      // Add cases for other error codes
      case "error.unable_to_process_order":
        return <FormattedMessage
          id="error.unable_to_process_order"
          defaultMessage="We're unable to process your order due to an error. Please contact our staff at {contactNumber} to report this or to get help"
          values={{
            contactNumber: props.contactNumber
          }}
        />;
      case "error.out_of_stock":
        return <FormattedMessage
          id="error.out_of_stock_text"
          defaultMessage="Sorry, some items in your cart are sold out. Please remove them to place your order."
        />;
      default: {
        return <FormattedMessage
          id="error.something_went_wrong_try_again"
          defaultMessage="Something went wrong. Please try again later."
        />;
      }
    }
  }

  return (
    <Modal show={show} dialogClassName="custom curved" centered backdrop="static">
      <ModalCloseIcon onClick={handleClose} />
      <div className="error-popup">
        {props.error.titleCode ? (
          <p className="error-title">{errorTitle(props.error.titleCode)}</p>
        ) : props.error.title ? (
          <p className="error-title">{props.error.title}</p>
        ) : false}

        {props.error.subTitleCode ? (
          <p className="error-sub-title">{errorSubTitle(props.error.subTitleCode)}</p>
        ) : props.error.subTitle ? (
          <p className="error-sub-title">{props.error.subTitle}</p>
        ) : false}

        {props.error.messageCode ? (
          <p className="error-message">{errorMessage(props.error.messageCode)}</p>
        ) : props.error.message ? (
          <p className="error-message">{props.error.message}</p>
        ) : false}

        <button
          className="button btn btn-primary btn-block"
          onClick={handleClose}
        >
          <FormattedMessage
            id="global.uppercase.ok"
            defaultMessage="OK"
          />
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let error = state.error;
  let contactNumber = state.outlet.call_now_message;

  return {
    error,
    contactNumber,
  };
};

const mapDispatchToProps = {
  resetError: errorOperations.resetError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPopup);

// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Redux Operations
import { loyaltyOperations } from "../../state/features/loyalty";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import {
  calculateAmountsAndBreakdowns,
  formatDecimal,
} from "../../helpers/itemCalculations";

interface ILoyaltyWidgetProps {
  loyalty: any;
  cart: any;
  currency: string;
  menu: any;
  orderType: any;
  discount: any;
  selectedArea: any;
  deliveryChargeId: any;
  fetchLoyalty: any;
  redeemLoyalty: any;
  removeLoyalty: any;
  setCart: any;
}

interface ILoyaltyWidgetState {
  pointsToRedeem: any;
  redeemError: any;
  fetchError: any;
}

class LoyaltyWidget extends Component<
  ILoyaltyWidgetProps,
  ILoyaltyWidgetState
> {
  constructor(props: ILoyaltyWidgetProps) {
    super(props);
    this.state = {
      pointsToRedeem: "",
      redeemError: null,
      fetchError: null,
    };

    this.props.fetchLoyalty().then((response: any) => {
      if (response.error) {
        this.setState({
          fetchError: "We're unable to fetch your loyalty points.",
        });
      }
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.loyalty !== this.props.loyalty) {
      this.updateCart();
    }
    if (
      prevProps.cart.totalBeforeLoyalty !== this.props.cart.totalBeforeLoyalty
        && this.props.loyalty?.hasRedeemed
    ) {
      this.redeemLoyalty();
    }
  }

  onLoyaltyPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      redeemError: null,
      pointsToRedeem: e.target.value,
    });
  };

  removeLoyalty = () => {
    this.setState(
      {
        redeemError: null,
        pointsToRedeem: "",
      },
      () => this.props.removeLoyalty()
    );
  };

  redeemLoyalty = () => {
    const userLoyalty = this.props.loyalty;
    const pointsToRedeem = parseFloat(this.state.pointsToRedeem || 0);
    const availableLoyalty = parseFloat(userLoyalty.customer_loyalty || 0);
    const minRedeemPoints = parseFloat(userLoyalty.min_redeem_value || 0);
    const maxRedeemPoints = parseFloat(userLoyalty.max_redeem_value || 0);

    if (pointsToRedeem > availableLoyalty) {
      this.setState(
        {
          redeemError: this.loyaltyErrorMessage("insufficient_points", formatDecimal(availableLoyalty)),
          pointsToRedeem: "",
        },
        () => this.props.removeLoyalty()
      );
      return;
    } else if (maxRedeemPoints != 0 && pointsToRedeem > maxRedeemPoints) {
      this.setState(
        {
          redeemError: this.loyaltyErrorMessage("above_max_points_for_an_order", formatDecimal(maxRedeemPoints)),
          pointsToRedeem: "",
        },
        () => this.props.removeLoyalty()
      );
      return;
    } else if (
      pointsToRedeem > parseFloat(this.props.cart.totalBeforeLoyalty)
    ) {
      this.setState(
        {
          redeemError: this.loyaltyErrorMessage("above_max_points_for_this_order", formatDecimal(this.props.cart.totalBeforeLoyalty)),
          pointsToRedeem: "",
        },
        () => this.props.removeLoyalty()
      );
      return;
    } else if (availableLoyalty < minRedeemPoints) {
      this.setState(
        {
          redeemError: this.loyaltyErrorMessage("below_min_points", formatDecimal(minRedeemPoints)),
          pointsToRedeem: "",
        },
        () => this.props.removeLoyalty()
      );
      return;
    } else {
      this.setState(
        {
          redeemError: null,
        },
        () => this.props.redeemLoyalty(pointsToRedeem)
      );
    }
  };

  loyaltyErrorMessage = (type: string, loyaltyPoints: any) => {
    switch (type) {
      case "insufficient_points":
        return (
          <FormattedMessage
            id="loyalty.validation.only_have_some_loyalty"
            defaultMessage="You only have {loyaltyPoints} Loyalty {currency} available."
            values={{
              loyaltyPoints: loyaltyPoints,
              currency: this.props.currency,
            }}
          />
        );
      case "above_max_points_for_an_order":
        return (
          <FormattedMessage
            id="loyalty.validation.only_redeem_upto_for_an_order"
            defaultMessage="You can only redeem upto {loyaltyPoints} Loyalty {currency} for an order."
            values={{
              loyaltyPoints: loyaltyPoints,
              currency: this.props.currency,
            }}
          />
        );
      case "above_max_points_for_this_order":
        return (
          <FormattedMessage
            id="loyalty.validation.only_redeem_upto_for_this_order"
            defaultMessage="You can only redeem upto {loyaltyPoints} Loyalty {currency} for this order."
            values={{
              loyaltyPoints: loyaltyPoints,
              currency: this.props.currency,
            }}
          />
        );
      case "below_min_points":
        return (
          <FormattedMessage
            id="loyalty.validation.need_minimum_loyalty"
            defaultMessage="You need a minimum of {loyaltyPoints} Loyalty {currency} to redeem for this order."
            values={{
              loyaltyPoints: loyaltyPoints,
              currency: this.props.currency,
            }}
          />
        );
      default: return "";
    };
  }

  updateCart = () => {
    let cart = calculateAmountsAndBreakdowns(
      this.props.cart.items,
      this.props.menu,
      this.props.orderType,
      this.props.discount,
      this.props.loyalty.redeemedPoints,
      this.props.selectedArea,
      this.props.deliveryChargeId,
    ) as any;
    cart.items = this.props.cart.items;

    this.props.setCart(cart);
  };

  render() {
    const { pointsToRedeem, redeemError, fetchError } = this.state;
    const { loyalty, currency } = this.props;
    return (
      <React.Fragment>
        {fetchError || !Object.keys(loyalty).length ? (
          <div className="widget loyalty-sections d-flex justify-content-center align-items-center">
            <p className="fetch-error text-center">
              <FormattedMessage
                id="loyalty.unable_to_retrieve"
                defaultMessage="We're unable to fetch your loyalty points."
              />
            </p>
          </div>
        ) : (
          <div className="widget loyalty-sections">
            <div className="form-group mb-2 w-100">
              <label className="input-label bold">
                <FormattedMessage
                  id="global.loyalty_points"
                  defaultMessage="Loyalty Points"
                />:{" "}
                <span className="loyalty-ponits">
                  {formatDecimal(loyalty.customer_loyalty || 0)} Loyalty {currency}
                </span>
              </label>
              {loyalty.redeemedPoints ? (
                <React.Fragment>
                  <p className="redeemed-loyalty">
                    <FormattedMessage
                      id="loyalty.you_have_redeemed"
                      defaultMessage="You have redeemed <spanTag>{currency} {redeemedPoints}</spanTag>"
                      values={{
                        currency: currency,
                        redeemedPoints: formatDecimal(loyalty.redeemedPoints),
                        spanTag: (text) => <span className="label">{text}</span>,
                      }}
                    />
                  </p>
                  <p className="remove-button" onClick={this.removeLoyalty}>
                    <FormattedMessage
                      id="loyalty.remove_loyalty"
                      defaultMessage="Remove Loyalty"
                    />
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex">
                    <FormattedMessage
                      id="loyalty.enter_points"
                      defaultMessage="Enter Points"
                    >
                      {(placeholder) => (
                        <input
                          name="address_2"
                          type="number"
                          required
                          className="form-control"
                          placeholder={placeholder}
                          autoComplete="off"
                          value={pointsToRedeem}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.onLoyaltyPointsChange(e)
                          }
                        ></input>
                      )}
                    </FormattedMessage>
                    <button
                      className="btn px-4 ml-2 btn-primary"
                      onClick={this.redeemLoyalty}
                      disabled={!pointsToRedeem || redeemError}
                    >
                      <FormattedMessage
                        id="global.apply"
                        defaultMessage="Apply"
                      />
                    </button>
                  </div>
                  {redeemError && (
                    <p className="discount-error mt-2">{redeemError}</p>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  let loyalty = state.loyalty;
  let cart = state.cart;
  let currency = state.company.currency;
  let menu = state.menu;
  let orderType = state.session.order_type;
  let discount = state.discount;
  let selectedArea = state.areas.selectedArea;
  let deliveryChargeId = state.outlet.delivery_charge_id;

  return {
    loyalty,
    cart,
    currency,
    menu,
    orderType,
    discount,
    selectedArea,
    deliveryChargeId,
  };
};

const mapDispatchToProps = {
  fetchLoyalty: loyaltyOperations.fetchLoyalty,
  redeemLoyalty: loyaltyOperations.redeemLoyalty,
  removeLoyalty: loyaltyOperations.removeLoyalty,
  setCart: cartOperations.setCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyWidget);

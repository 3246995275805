import actions from "./actions";

const verifyPayment = actions.verifyPayment;
const buildCCAvenuePaymentData = actions.buildCCAvenuePaymentData;
const fetchMyFatoorahPaymentModes = actions.fetchMyFatoorahPaymentModes;
const buildQpayPaymentData = actions.buildQpayPaymentData;
const buildMastercardSession = actions.buildMastercardSession;
const buildIPay88PaymentData = actions.buildIPay88PaymentData;
const buildFiservPaymentData = actions.buildFiservPaymentData;
const omisePlaceOrder = actions.omisePlaceOrder;
const buildAdyenPaymentMethods = actions.buildAdyenPaymentMethods;
const createAdyenPayment = actions.createAdyenPayment
const initiateStripeConnectTransaction = actions.initiateStripeConnectTransaction;
const fetchStripeConnectPaymentIntent = actions.fetchStripeConnectPaymentIntent;

export default {
  verifyPayment,
  buildCCAvenuePaymentData,
  fetchMyFatoorahPaymentModes,
  buildQpayPaymentData,
  buildIPay88PaymentData,
  buildFiservPaymentData,
  omisePlaceOrder,
  buildAdyenPaymentMethods,
  createAdyenPayment,
  initiateStripeConnectTransaction,
  fetchStripeConnectPaymentIntent,
  buildMastercardSession,
};

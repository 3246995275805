import * as types from "./types";

const cartReducer = (state = {}, action: any) => {
  switch (action.type) {
    case types.SET_CART:
      return  {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
};

const reducer = cartReducer;

export default reducer;

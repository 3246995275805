// Packages
import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import CardSectionWrapper from "../cardSectionWrapper";
import CcAvenueForm from "../ccAvenueForm";
import FiservForm from "../fiservForm";
import QpayForm from "../qpayForm";
import QpayCardSectionWrapper from "../qpayCardSectionWrapper";
import IPay88Form from "../iPay88Form";
import InlineLoader from "../../components/inlineLoader";
import OmiseCardSectionWrapper from "../../components/omiseCardSectionWrapper";
import AdyenSectionWrapper from "../../components/adyenSectionWrapper";
import StripeConnectWrapper from "../../mobileComponents/paymentTypes/stripeConnect/wrapper";
import MasterCardSectionWrapper from "../../components/masterCardSectionWrapper";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave, faCreditCard } from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { loyaltyOperations } from "../../state/features/loyalty";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import TipsWidgetMobile from "../tipsWidgetMobile";
import { calculateAmountsAndBreakdowns, formatDecimal } from "../../helpers/itemCalculations";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import { MASTERCARD_PAYMENT_TYPE } from "../../helpers/constants";

interface IPaymentDetailsMobileProps {
  paymentTypes: any;
  payload:any;
  user: any;
  userEmail: string;
  userName: string;
  loyalty: any;
  orderType: string;
  loyaltyEnabled: boolean;
  cardToken: any;
  handleStripeCardSelection: any;
  showPointsEarnedPopup: any;
  onPaymentSelect: any;
  updateUserEmail: any;
  fetchLoyalty: any;
  myFatoorahSelectedPaymentMode: any;
  updateMyFatoorahSelectedPaymentMode: any;
  fetchMyFatoorahPaymentModes: any;
  togglePlaceOrderButton: any;
  updateCardToken: any;
  companyDetails: any;
  setTipAmount: any;
  cart: any;
  menu: any;
  currency: any;
  discount: any;
  tipsEnabled: boolean;
  selectedArea: any;
  outlet: any;
}

function PaymentDetailsMobile(props: IPaymentDetailsMobileProps) {
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [myFatoorahPaymentModes, setMyFatoorahPaymentModes] = useState(
    [] as any
  );
  const [myFatoorahLoading, setMyFatoorahLoading] = useState(true)
  const [showTips, setShowTips] = useState(true);
  const [removeTip, setRemoveTip] = useState(false);
  const [tipAmount, setTipAmount] = useState(0);
  const [cartItems, setCartItems] = useState([]);

  const initialState: any = {
    amountBreakdowns: {
      subTotal: 0,
      total: 0,
      taxTotal: 0,
      discountTotal: 0,
      loyaltyTotal: 0,
      tip: 0,
      surchargeBreakDown: [],
    },
  };
  const [state, dispatch] = useReducer((state: any, action: any) => {
    switch (action.type) {
      case "setAmountBreakdowns":
        return { ...state, amountBreakdowns: action.data };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    if (
      props.user.id &&
      props.loyaltyEnabled &&
      Object.keys(props.loyalty).length === 0
    ) {
      props.fetchLoyalty();
    }
  }, [props.user.id]);

  useEffect(() => {
    if (!selectedPaymentType && props.paymentTypes.length > 0) {
      setSelectedPaymentType(props.paymentTypes[0].payment_type);
      handlePaymentType(props.paymentTypes[0]);
    }
  }, [props.paymentTypes]);

  useEffect(() => {
    updateAmountBreakdowns();
  }, [props.cart]);

  useEffect(() => {
    updateAmountBreakdowns();
    props.setTipAmount(tipAmount);
  }, [tipAmount]);

  useEffect(() => {
    if (
      props.tipsEnabled &&
      !["cash_on_delivery", "card_on_delivery"].includes(selectedPaymentType) &&
      props.orderType === "delivery"
    ) {
      setShowTips(true);
    } else {
      setShowTips(false);
      setTipAmount(0);
      props.setTipAmount(0);
    }
  }, [props.tipsEnabled, selectedPaymentType, props.orderType]);

  const handlePaymentType = (paymentType: any) => {
    setSelectedPaymentType(paymentType.payment_type);
    props.onPaymentSelect(paymentType.payment_type, paymentType.title)

    if (paymentType.payment_type == "my_fatoorah" && props.myFatoorahSelectedPaymentMode == "") {
      handleMyFatoorahPaymentModes(paymentType)
    };
  };

  const handleMyFatoorahPaymentModes = (paymentType: any) => {
    props.fetchMyFatoorahPaymentModes(props.cart.total).then((response: any) => {
      if (!response.error) {
        setMyFatoorahPaymentModes(response.payload.data);
        setMyFatoorahLoading(false);
        props.updateMyFatoorahSelectedPaymentMode(response.payload.data[0]?.id);
      }
    })
  };

  const emailBoxShouldShow = (paymentType: any, selectedPaymentType: any, email: any) => {
    if (!email) {
      return (
        (paymentType.payment_type == "pay_u" &&  selectedPaymentType == "pay_u") ||
        (paymentType.payment_type == "qpay" &&  selectedPaymentType == "qpay")
      );
    }
    return false;
  };

  const updateAmountBreakdowns = () => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      props.cart.items,
      props.menu,
      props.orderType,
      props.discount,
      props.loyalty.redeemedPoints,
      props.selectedArea,
      props.outlet.delivery_charge_id,
      tipAmount || 0,
    ) as any;
    dispatch({ type: "setAmountBreakdowns", data: amountBreakdowns });
  };

  const updateTipAmount = (tipAmount: any) => {
    setTipAmount(tipAmount);
    props.setTipAmount(tipAmount);
  }

  const removeTipFromBreakdown = () => {
    setTipAmount(0);
    props.setTipAmount(0);
  };

  return (
    <div className="payment-details">
      <label className="input-label bold mb-0">
        <FormattedMessage
          id="checkout.payment_options"
          defaultMessage="Payment Options"
        />
      </label>
      <div className="payment-methods">
        <div>
          {props.paymentTypes.map((paymentType: any) => {
            return (
              <div
                key={paymentType.id}
                className={
                  selectedPaymentType === paymentType.payment_type
                    ? "form-check method selected"
                    : "form-check method"
                }
                onClick={() => handlePaymentType(paymentType)}
              >
                <label
                  className="form-check-label"
                  htmlFor={paymentType.payment_type}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    id={paymentType.payment_type}
                    checked={selectedPaymentType === paymentType.payment_type}
                    readOnly
                  />
                  <FontAwesomeIcon
                    icon={
                      paymentType.payment_type == "cash_on_delivery"
                        ? faMoneyBillWave
                        : faCreditCard
                      }
                    size="sm"
                  />
                  <span>{paymentType.title}</span>
                </label>
                {paymentType.payment_type == "my_fatoorah" &&
                selectedPaymentType == "my_fatoorah" && (
                  <div className="payment-subsection">
                    {myFatoorahLoading ? (
                      <div className="loader-container">
                        <InlineLoader />
                      </div>
                    ) : (
                    <div className="selections">
                      {myFatoorahPaymentModes.length > 1 ? (
                        <small>
                          <FormattedMessage
                            id="checkout.choose_payment_mode"
                            defaultMessage="Choose your payment mode"
                          />
                        </small>
                      ) : null}

                      {myFatoorahPaymentModes.map((myFatoorahPaymentMode: any) => {
                        return(
                          <ul key={myFatoorahPaymentMode.id} >
                            <li>
                              <label>
                                <input
                                  type="radio"
                                  name="myFatoorahSelectedPaymentMode"
                                  onClick={() =>
                                    props.updateMyFatoorahSelectedPaymentMode(myFatoorahPaymentMode.id)
                                  }
                                  checked={
                                    props.myFatoorahSelectedPaymentMode ==
                                    myFatoorahPaymentMode.id
                                  }
                                />
                                {myFatoorahPaymentMode.title}
                              </label>
                            </li>
                          </ul>
                        )
                      })}
                    </div>
                    )}
                  </div>
                  )}

                {paymentType.payment_type == "stripe" &&
                  selectedPaymentType == "stripe" && (
                    <CardSectionWrapper
                      stripeKey={paymentType.public_key}
                      payload={props.payload}
                      onCardSelect={(cardData: any) =>
                        props.handleStripeCardSelection(cardData)
                      }
                      showPointsEarnedPopup={(earnedLoyalty: any) =>
                        props.showPointsEarnedPopup(earnedLoyalty)
                      }
                      togglePlaceOrderButton={(status: boolean) =>
                        props.togglePlaceOrderButton(status)
                      }
                    />
                  )}

                {paymentType.payment_type == "stripe_connect" &&
                  selectedPaymentType == "stripe_connect" && (
                  <StripeConnectWrapper
                    orderPayload={props.payload}
                    togglePlaceOrderButton={(status: boolean) =>
                      props.togglePlaceOrderButton(status)
                    }
                  />
                )}

                {paymentType.payment_type == "qpay" &&
                selectedPaymentType == "qpay" &&
                paymentType.can_save_cards && (
                  <QpayCardSectionWrapper
                    updateCardToken={(token: any) =>
                      props.updateCardToken(token)
                    }
                  />
                )}

                  {paymentType.payment_type == "omise" &&
                    selectedPaymentType == "omise" && (
                      <OmiseCardSectionWrapper
                        omiseKey={ paymentType.public_key }
                        payload={ props.payload }
                        currency={ props.companyDetails.currency }
                        showPointsEarnedPopup={(earnedLoyalty: any) =>
                          props.showPointsEarnedPopup(earnedLoyalty)
                        }
                        togglePlaceOrderButton={(status: boolean) =>
                          props.togglePlaceOrderButton(status)
                        }
                        companyDecimalPrecision={ props.companyDetails.decimal_precision }
                      />
                  )}

                {paymentType.payment_type == "adyen" &&
                    selectedPaymentType == "adyen" && (
                    <div className="payment-subsection adyen">
                      <div className="selections">
                        <AdyenSectionWrapper
                          payload={props.payload}
                          paymentType={paymentType}
                          showPointsEarnedPopup={(earnedLoyalty: any) =>
                            props.showPointsEarnedPopup(earnedLoyalty)
                          }
                          togglePlaceOrderButton={(status: boolean) =>
                            props.togglePlaceOrderButton(status)
                          }
                        />
                      </div>
                    </div>
                  )}

                  {paymentType.payment_type == MASTERCARD_PAYMENT_TYPE &&
                    selectedPaymentType == MASTERCARD_PAYMENT_TYPE && (
                    <MasterCardSectionWrapper
                      orderPayload={props.payload}
                      togglePlaceOrderButton={(status: boolean) =>
                        props.togglePlaceOrderButton(status)
                      }
                    />
                  )}

                  {emailBoxShouldShow(paymentType,
                  selectedPaymentType, props.user.email) ? (
                    <div className="customer-details-section-mb mt-2">
                      <div className="add-customer-details-mb mt-2">
                        <div className="heading">
                        </div>
                        <div className="form-group add-customer-form-mb">
                          <React.Fragment>
                            <label className="input-label bold mb-2">
                              <FormattedMessage
                                id="global.email"
                                defaultMessage="Email"
                              />
                            </label>
                            <input
                              className="form-control custom-input mb-2"
                              type="text"
                              value={props.userEmail}
                              onChange={(event: any) => {
                                props.updateUserEmail(event.target.value)
                              }}
                              id="user_email"
                              required
                            />
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  ) : null}
              </div>
            );
          })}
        </div>
        {selectedPaymentType == "cc_avenue" && (
          <CcAvenueForm
            CCAvenueFormData={props.payload}
          />
        )}
        {selectedPaymentType == "qpay" && (
          <QpayForm
            qpayFormData={props.payload}
            email={props.userEmail}
            name={props.userName}
            cardToken={props.cardToken}
          />
        )}
        {selectedPaymentType == "i_pay88" && (
          <IPay88Form
            IPay88FormData={props.payload}
          />
        )}
        {selectedPaymentType == "fiserv" && (
          <FiservForm
            fiservFormData={props.payload}
          />
        )}
      </div>
      {showTips &&
       <TipsWidgetMobile
          setTipAmount={(tip: any) => updateTipAmount(tip)}
        />
      }
      <div className="charges-breakdown">
        <div className="charge">
          <p>Subtotal</p>
          <p>
            {props.currency} {formatDecimal(state.amountBreakdowns.subTotal)}
          </p>
        </div>
        {showTips && tipAmount > 0 ? (
          <div className="charge">
            <p>Tip {" "}
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline"
                }}
                onClick={() => removeTipFromBreakdown()}
              >
                Remove
              </span>
            </p>
            <p>
              {props.currency}{" "}{formatDecimal(state.amountBreakdowns.tips)}
            </p>
          </div>
        ) : null}
        {props.discount ? (
          <div className="charge">
            <p className="label">{props.discount.discount_name}</p>
            <p className="value">
              - {props.currency}{" "}
              {formatDecimal(props.discount.discountedAmount)}
            </p>
          </div>
        ) : null}
        {props.loyalty.redeemedPoints ? (
          <div className="charge">
            <p className="label">Loyalty</p>
            <p className="value">
              - {props.currency} {formatDecimal(props.loyalty.redeemedPoints)}
            </p>
          </div>
        ) : null}
        {state.amountBreakdowns.surchargeBreakDown.map(
          (surcharge: any, index: any) => {
            return (
              <div className="charge" key={index}>
                <p className="label">{surcharge.name}</p>
                <p className="value">
                  {props.currency} {formatDecimal(surcharge.amount)}
                </p>
              </div>
            );
          }
        )}
        {!props.menu.is_tax_inclusive && state.amountBreakdowns.taxTotal > 0 ? (
          <div className="charge">
            <p>Tax</p>
            <p>
              {props.currency} {formatDecimal(state.amountBreakdowns.taxTotal)}
            </p>
          </div>
        ) : null}
        <div className="charge strong">
          <p>Total</p>
          <p>
            {props.currency} {formatDecimal(state.amountBreakdowns.total)}
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let user = state.user;
  let loyalty = state.loyalty;
  let orderType = state.session.order_type;
  let loyaltyEnabled = state.loyaltyConfig[orderType]?.enabled;
  let cart = state.cart;
  let menu = state.menu;
  let currency = state.company.currency;
  let discount = state.discount;
  let tipsEnabled = state.company.tip_settings?.has_tips && state.company.tip_settings?.type == "pre_order";
  let selectedArea = state.areas.selectedArea;
  let outlet = state.outlet;

  return {
    user,
    loyalty,
    orderType,
    loyaltyEnabled,
    cart,
    menu,
    currency,
    discount,
    tipsEnabled,
    selectedArea,
    outlet,
  };
};

const mapDispatchToProps = {
  fetchLoyalty: loyaltyOperations.fetchLoyalty,
  fetchMyFatoorahPaymentModes: paymentTypeOperations.fetchMyFatoorahPaymentModes,
  setCart: cartOperations.setCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsMobile);

//Creates a copy of an Object
const deepCopyFunction = (inObject: any) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? ([] as any) : ({} as any);

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopyFunction(value);
  }

  return outObject;
};

const checkTint = (hex_color: any) => {
  let rgb: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex_color),
    r = parseInt(rgb[1], 16),
    g = parseInt(rgb[2], 16),
    b = parseInt(rgb[3], 16);

  r = Math.round(r * 0.299);
  g = Math.round(g * 0.587);
  b = Math.round(b * 0.114);

  var checker = r + g + b;
  return checker;
};

// Checks restaurant working hours
const findRestaurantWorkingHours = (workingHours: any, utcOffset: any) => {
  let dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let todayTime = new Date();
  let timeZoneOffset = 0;
  let todayTimeValue = todayTime.getTime();

  // Calculate difference between offset of store timezone and system timezone
  if (utcOffset) {
    timeZoneOffset = (utcOffset + todayTime.getTimezoneOffset() * 60) * 1000;
  }
  todayTime = new Date(todayTimeValue + timeZoneOffset);
  todayTimeValue = todayTime.getTime();
  let todayName = dayNames[todayTime.getDay()];
  let todayTimeSlots = workingHours[todayName];
  let slotStartTime = new Date(todayTimeValue);
  let slotEndTime = new Date(todayTimeValue);

  // Loop through today's working hours and find correct session
  for (let i = 0; i < todayTimeSlots.length; i++) {
    let splittedStartTime = todayTimeSlots[i].from.split(":");
    let splittedEndTime = todayTimeSlots[i].to.split(":");

    slotStartTime.setHours(splittedStartTime[0]);
    slotStartTime.setMinutes(splittedStartTime[1]);
    slotStartTime.setSeconds(0);
    slotEndTime.setHours(splittedEndTime[0]);
    slotEndTime.setMinutes(splittedEndTime[1]);
    slotEndTime.setSeconds(0);

    let slotStartTimeValue = slotStartTime.getTime();
    let slotEndTimeValue = slotEndTime.getTime();


    if (todayTimeValue <= slotStartTimeValue) {
      return {
        open: false,
        openingTime: new Date(slotStartTimeValue - timeZoneOffset),
        closingTime: new Date(slotEndTimeValue - timeZoneOffset),
        openingDay: "Today",
      };
    } else if (todayTimeValue <= slotEndTimeValue) {
      return {
        open: true,
        closingTime: new Date(slotEndTimeValue - timeZoneOffset),
        openingDay: "Today",
      };
    }
  }

  let dayName, daySlot, nextWorkingDaySlot;
  let nextWorkingDayOpeningTime = slotStartTime;
  let nextWorkingDayEndTime = slotEndTime;

  // Loop through each day's store timings and find the first upcoming opening time
  for (let i = 1; i <= 7; i++) {
    dayName = dayNames[(todayTime.getDay() + i) % 7];
    daySlot = workingHours[dayName][0];
    if (daySlot) {
      nextWorkingDaySlot = daySlot;
      nextWorkingDayOpeningTime.setDate(nextWorkingDayOpeningTime.getDate() + i);
      nextWorkingDayEndTime.setDate(nextWorkingDayEndTime.getDate() + i);
      break;
    }
  }

  // Return as Store Closed when there are no timings available for any day of the week
  if (!nextWorkingDaySlot) {
    return {
      open: false,
    };
  }

  // Split Time - Array with Hour and Minutes as two elements. Eg: ["08", "00"]
  let nextWorkingDayOpeningSplitTime = nextWorkingDaySlot.from.split(":");
  let nextWorkingDayEndSplitTime = nextWorkingDaySlot.to.split(":");

  nextWorkingDayOpeningTime.setHours(nextWorkingDayOpeningSplitTime[0]);
  nextWorkingDayOpeningTime.setMinutes(nextWorkingDayOpeningSplitTime[1]);
  nextWorkingDayOpeningTime.setSeconds(0);
  nextWorkingDayEndTime.setHours(nextWorkingDayEndSplitTime[0]);
  nextWorkingDayEndTime.setMinutes(nextWorkingDayEndSplitTime[1]);
  nextWorkingDayEndTime.setSeconds(0);

  let nextWorkingDayOpeningTimeValue = nextWorkingDayOpeningTime.getTime();
  let nextWorkingDayEndTimeValue = nextWorkingDayEndTime.getTime();
  // Return as Store Closed with the next store working time
  return {
    open: false,
    openingTime: new Date(nextWorkingDayOpeningTimeValue - timeZoneOffset),
    closingTime: new Date(nextWorkingDayEndTimeValue - timeZoneOffset),
    openingDay: dayName,
  };
};

// Finds ETA Display text for order tracking
const obtainEtaDisplayText = (eta: any, translationCallback: any) => {
  let currentTime = new Date();
  let currentDate = currentTime.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  let etaTime = new Date(eta);
  let etaDisplayTime = etaTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  let etaDate = etaTime.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  if (etaDate === currentDate) {
    etaDisplayTime = translationCallback({ id: "schedule.today_time", defaultMessage: "{time}" }, { time: etaDisplayTime });
  } else {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let tomorrowDate = tomorrow.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
    if (etaDate === tomorrowDate) {
      etaDisplayTime = translationCallback({ id: "schedule.tomorrow_time", defaultMessage: "Tomorrow {time}" }, { time: etaDisplayTime });
    } else {
      etaDisplayTime = etaDate + ", " + etaDisplayTime;
    }
  }
  return etaDisplayTime;
}

const isItemOutOfStock = (item: any, out_of_stock_items: any[]) => {
  if (
    out_of_stock_items?.length &&
    item?.id &&
    out_of_stock_items.includes(item.id) 
  ) {
    return true;
  }
  return false;
};

export {
  deepCopyFunction,
  findRestaurantWorkingHours,
  checkTint,
  obtainEtaDisplayText,
  isItemOutOfStock,
};


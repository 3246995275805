import * as types from "./types";

const setSession = (session: any) => ({
  type: types.SET_SESSION,
  payload: session,
});

const changeDeliveryArea = (area: any, orderType: string, pickupMode = "in_store") => ({
  type: types.CHANGE_DELIVERY_AREA,
  payload: {
    request: {
      method: "put",
      url: `/delivery_areas/${area.id}/`,
      data: {
        id: area.id,
        outlet_id: area.outlet_id,
        order_type: orderType,
        area_name: area.name,
        pickup_mode: pickupMode,
      }
    }
  }
})

export default {
  setSession,
  changeDeliveryArea
};
